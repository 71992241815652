<template>
  <div class="w-full">
    <div class="w-full flex flex-wrap mb-3">
      <div class="mr-4">
        <Checkbox v-model="form.isSelected" @change="toggleItemIsSelected" />
      </div>

      <ItemText title="Nosaukums" :text="form.rent_item.inventory_item.name" textClass="font-semibold" />
      
      
      <div class="w-full grid grid-cols-12 pl-11">

        <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 pb-4">
          <ItemText title="Periods" :text="`${form.start_date} / ${form.end_date}`" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
          <ItemText title="Mēnesī/Eur" :text="form.price" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
            <ItemText v-if="!form.by_quantity" title="Daudzums" :text="form.quantity.toFixed(2)"/>
            <Input v-if="form.by_quantity" type="number" name="quantity" placeholder="Skaits" v-model="form.quantity"
                   @change="calculateTotal"/>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
          <ItemText title="Kopā EUR" :text="form.total" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4 pr-4">
          <Input type="number" name="discount" placeholder="Atlaide %" v-model="form.discount" @change="calculateTotal" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4 pr-4">
          <Input type="number" name="vat_rate" placeholder="PVN %" v-model="form.vat_rate" @change="calculateTotal" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2 pb-4">
          <ItemText title="Summa EUR" :text="form.total_with_discount" />
        </div>

      </div>
    </div>

    <div class="w-full pl-11">
      <template v-for="(reading, index) in item.readings" :key="reading.id">
        <div class="w-full grid grid-cols-12 mb-3">
          
          <template v-if="!reading.is_last_reading">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2 pb-4">
              <ItemText :title="reading.name" :text="reading.reading ? reading.reading : 0" />
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2 pb-4 pr-4">
              <Input type="number" name="newReading" placeholder="Skaitītāja rādījums" v-model="reading.newReading"   @change="calculateReading(index)" :errors="errors[`newReading-${reading.id}`]" />
            </div>
          </template>

          <template v-if="reading.is_last_reading">
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
              <ItemText :title="reading.name" :text="reading.previous_reading" />
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
              <ItemText :title="reading.name" :text="reading.reading" />
            </div>
          </template>

          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
            <ItemText title="Cena/Eur" :text="reading.price" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
            <ItemText title="Daudzums" :text="reading.quantity" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4">
            <ItemText title="Kopā EUR" :text="reading.total.toFixed(2)" />
          </div>

          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-1 pb-4 pr-4">
            <Input type="number" name="reading_discount" placeholder="Atlaide %" v-model="reading.discount" @change="calculateReading(index)" />
          </div>

          <div class="col-span-12 sm:col-span-6 lg:col-span-2 pb-4">
            <ItemText title="Summa EUR" :text="reading.total_with_discount.toFixed(2)" />
          </div>
        </div>

      </template>
    </div>
  </div>


</template>

<script>
import {mapGetters} from "vuex"
import Calculations from "@/services/Calculations/calculations";
import Checkbox from "@/components/Components/Checkbox"
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"

export default {
  name: "ClosedRentItem",
  components: {
    Checkbox,
    ItemText,
    Input,
  },
  props: ['item', 'toggleItemIsSelected', 'recalculateTotals'],
  data: () => ({
    form: null,
  }),
  computed: {
    ...mapGetters({
      errors: "errors"
    })
  },
  created() {
    this.form = this.item
    this.calculateTotal()
  },
  methods: {
    calculateTotal() {
      if(this.form.by_quantity) {
          this.form.total =  this.form.quantity * this.form.price
      }

      this.form.total_with_discount = +Calculations.getPriceWithDiscount(this.form.total, this.form.discount)
      this.form.total_with_readings =
        (this.form.readings.length > 0)
          ? Calculations.getItemsTotalWithReadingsAndDiscounts(this.form)
          : this.form.total_with_discount
      this.form.vat = (Calculations.getVat(this.form.total_with_readings, this.form.vat_rate)).toFixed(2)
      this.form.total_with_vat = ((this.form.total_with_discount * 1) + +this.form.vat).toFixed(2)
      this.recalculateTotals()
    },
    calculateReading(readingIndex) {
      this.item.readings.forEach((reading, index,) => {
        if (index === readingIndex) {
          return Calculations.getReadingData(reading, this.item.end_date)
        }
      })
      this.calculateTotal()
    },
  }
}
</script>

<style>

</style>